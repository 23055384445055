@import url(https://fonts.googleapis.com/css?family=Montserrat);

#contactUsContent a {
    color:white;
}

#historical-data-table {
    .MuiTableCell-head, .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:first-child {
        background-color:#2a5cc1;
        color:white;
        
    }

    .MuiTableCell-head, .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
        white-space:nowrap;
        font-size:12px;
    }
}

#side-bar-list{
    .MuiTypography-body1{
        font-weight:600;
        color: #1c1c1c;
    } 

    .sidemenu-active .MuiTypography-body1 {
        font-weight: 700;
        text-transform: uppercase;
        color: #d51616;
    }
}

// #assumptions-table-dialog{
//     max-width: 1200px;
// }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.table-text-field{
    width:100%;
}


.MuiTabs-flexContainer{
    flex-wrap: wrap;
}


.MuiTableBody-root{
    .invalid-cell{
        border-color: #d51616 !important;
        border-width: 2;
    }
}

.html2canvas-container {
    width: 4000px !important;
    height: 4000px !important;
}
